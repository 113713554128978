body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

pre {
  color: #fff;
}

:root {
  --chattitle: #85f6e4;
}

textarea.form-control {
  min-height: 35px;
}

/* ------------------ ------------------ */
.vuho-activity {
  text-align: center;
  font-size: 75%;
  padding-bottom: 25px;
}

.vuho-activity span {
  display: block;
  color: #797979;
  /*margin-right: 20px;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control {
  background-color: #102625;
  border-color: #183433;
  font-weight: 300;
}

.page-link {
  cursor: pointer;
}

/* ------------------ ------------------ */
.fqview-tabs .nav-link {
  color: white !important;
}

/* ------------------ ------------------ */
.fq-field {
  margin-left: 7px;
  margin-right: 7px;
}
.fq-field__note {
  color: #b9aba0;
  font-size: 69%;
  font-style: italic;
}
/* ------------------ ------------------ */
.fq-table__search {
  padding: 0 4px;
  display: flex;
  flex-direction: row;
}

.fq-table__search-fq {
  margin-right: 15px;
}

.fq-table__search-names {
  flex: 1;
}

.fq-table__noresults {
  text-align: center;
  padding: 75px 25px;
  background: rgba(0, 0, 0, .2)
}

.fq-table-table {
  margin-top: 3px;
}

.fq-table-table td span {
  cursor: pointer;
}

.fq-table-panel {
  height: 26px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1f1c1a;
  color: white;
}

.fq-table-container {
  padding-bottom: 50px;
  position: relative;
}

.fq-table-container input {
  margin-bottom: 4px;
}


.fq-tile {
  padding-left: 15px;
  padding-right: 15px;
  line-height: 25px;
  display: inline-block;
  border: 1px solid #ccc;
}

.fq-tile:hover {
  background-color: #ccc;
  cursor: pointer;
}

.fq-navigate-cell {
  width: 200px;
}

.fq-navigate-cell i {
  font-style: normal;
  font-size: 75%;
  display: block;
  font-weight: lighter;
  color: var(--chattitle);
}

.fq-navigate-cell span,
.fq-cross-link {
  border-bottom: 1px dotted white;

}

.fq-cross-link:hover {
  text-decoration: none;
}

.fq-navigate-cell {
  position: relative;
  padding-right: 34px !important;
}

.fq-navigate-cell__search {
  padding-right: 0 !important;
}

.fq-navigate-cell:hover {
  background-color: #003d3d;
  color: white;
  cursor: pointer;
}

.fq-navigate-cell:hover span {
  color: white;
}

.fq-navigate-cell__button {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  /*border: 1px solid var(--chattitle);*/
  /*background: #1f1c1a;*/
}

.fq-navigate-cell__button:hover {
  background: var(--chattitle);
}

.fq-navigate-cell__button:active {
  opacity: 0.7
}

.fq-cross-link-container {
  display: block;
  margin-right: 38px;
}

.fq-cross-link-container:hover {
  /*background-color: #000;*/
}

.fq-cross-link:hover {
  text-decoration: none;
}

.fq-cross-link {
  margin-right: 5px;
  border-bottom: 1px dotted white;
}

.fq-cross-link:hover {
  background-color: #003d3d;
  color: white;
  cursor: pointer;
}

.fq-cross-links i {
  color: var(--chattitle);
}

.fq-cross-link.fq-cross-link-active {
  border-bottom: none;
  opacity: .5
}

.fq-names-textarea {
  width: 100%;
  font-size: 80%;
}
.fq-namestab__names {
  line-height: 1.1;
}
.fq-namestab__nameslist {
  margin-bottom: 20px;
}
.fq-namestab__container {
  margin: 4px 13px 0;
}

.fq-namestab__name {
  position: relative;
}
.fq-namestab__name:hover {
  cursor: pointer;
  border-bottom: 1px dotted #ff6b6b;
  color: #ff6b6b;
}

/*.fq-namestab__name-remove {*/
/*  position: absolute;*/
/*  display: none;*/
/*  right: 0;*/
/*  top: 0;*/
/*}*/
/*.fq-namestab__name:hover .fq-namestab__name-remove {*/
/*  display: block;*/
/*}*/

.auth {
  text-align: right;
  line-height: 37px;
  color: white;
}

.auth__link {
  cursor: pointer;
  color: white
}

.auth__logout {
  cursor: pointer;
  color: white
}

.fq-wrapper {
  position: relative;
}

.fq-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #1c3035;
  z-index: 10;
  padding: 1px 10px;
  /*min-width: 520px;*/
  display: flex;
}

.fq-nav__tabs {
}

.fq-nav__auth {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}
.fq-nav__auth--dev {
  background-color: indigo;
}

.fq-outlet {
  margin-top: 44px;
}

.fq-wrapper-iniframe .fq-outlet {
  margin-top: 0;
}

/* ------------------ ------------------ */
.fqview-topcontainer {
  display: flex;
}

.fqview-topcontainer__name {
  /*display: inline-block;*/
}

.fqview-topcontainer__name h1 {
  /*display: inline-block;*/
}

.fqview-topcontainer__chattitle {
  /*display: inline-block;*/
  height: 48px;
  flex: 1;
  padding-top: 5px;
  padding-left: 20px;
}

.fqview-topcontainer__chattitle input {
  width: 100%;
  font-size: 120%;
  color: var(--chattitle);
}

.fqview-chattitle__text {
  line-height: 40px;
}

.fqview-tabcontainer {
  padding-top: 10px;
}

.fqview-container {
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 400px;
}

/* ------------------ ------------------ */
.fq-names-cell {
  line-height: 1.2;
}

.fq-names-cell span {
}

/* ------------------ ------------------ */
.daystory {
  margin-bottom: 20px;
}

.daystory__value {
  margin-left: 20px;
}

.daystory__value .form-control {
  min-height: 49px;
}

.daystory__date {
  width: 150px;
}

/* ------------------ ------------------ */
.maptab {
  /*padding: 0 7px 25px 7px;*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.maptab__form {
  margin-bottom: 5px;
  padding: 0 10px;
}

.maptab__map {
  flex: 1;
  /*height: 500px;*/
}

.maptab__conversion {
  display: flex;
  flex-direction: column;
}

.maptab__conversion-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.maptab__form-toprow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.maptab__conversion_proj {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 12px;
}

.maptab__conversion_proj-text {
  align-self: flex-end;
  padding-bottom: 10px;
  flex-direction: column;
  width: 50px;
}

.maptab__conversion_proj-type {
  /*padding-top: 5px;*/
}

.maptab__conversion_proj-copy {
  width: 20px;
  background-color: #1c3035;
  margin-right: 5px;
}

.maptab__conversion_proj-copy:hover {
  background-color: var(--chattitle);
  cursor: pointer;
}

.maptab__conversion_field {
  margin-right: 5px;
}

.maptab__conversion_proj-wgs84str {
  line-height: 23px;
  font-size: 80%;
}
.maptab__kbb_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
}
.maptab__kbb_row__text {
  min-width: 40px;
  padding-right: 10px;
}
.maptab__textarea {
  font-size: 75%;
}
.maptab__kbb_row__form {
  flex: 1
}


.map, .map iframe {
  min-height: 600px;
  height: 100%;
}

/* ------------------ ------------------ */
.create-message {
}
.create-message__clean {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  opacity: .3;
}

.create-message {
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.33);
}

.create-message__content {
  position: relative;
  background-color: #18202f;
  padding: 10px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


.create-message__bottom {
  padding: 10px;
  background-color: #222835;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.create-message__textarea {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #073231;
}


.create-message__comment {
  margin-bottom: 10px
}

.create-message__comment textarea {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

/* ------------------ ------------------ */
.create-message__callsigns {
  margin-bottom: 20px;
  /*display: flex;*/
  /*flex-direction: row;*/
  padding-top: 7px;
  position: relative;

}

.create-message__callsigns-add {
  position: absolute;
  top: 0;
  right: -35px;
}

.create-message__callsigns-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.create-message__callsign {
  padding-bottom: 5px;
  /*max-width: 115px;*/
  min-width: 100px;
  position: relative;
  margin-right: 5px;
}

.create-message__signal-preview {
  text-align: left;
}
.create-message__audio {
  /*font-style: italic;*/
  margin-left: 10px;
  line-height: 1;
  margin-bottom: 3px;
  color: #98a1b5;
  font-weight: bold;
  font-size: 90%;
}

/* ------------------ ------------------ */
.pelengform__row {
  display: flex;
  flex-direction: row;
  position: relative;
}

.pelengform__row-add {
  position: absolute;
  top: 22px;
  right: -10px;
  cursor: pointer;
  display: none;
  z-index: 12;
}

.pelengform__row-add:hover svg {
  fill: #85f6e4;
}

/*.pelengform__row-add svg path {*/
/*    background-color: #003d3d;*/
/*}*/
.pelengform__row:hover .pelengform__row-add {
  display: block;
}

.pelengform__home {
  display: flex;
  flex-direction: row;
}

.pelengform__home-select {
  font-size: 12px;
}

.pelengform__home-input {
  width: 62px;
  margin-left: 5px;
}

/* ------------------ ------------------ */
.create-message__groups {
  user-select: none;
}

.create-message__group {
  margin-bottom: 4px;
  display: inline-block;
  margin-right: 10px;
}

.create-message__group_disabled {
  opacity: 0.5;
}

.create-message__grouptitle {
  color: white;
  position: relative;
  cursor: pointer;

}

.create-message__groupname {
  margin-left: 25px;
  margin-bottom: 0;
  cursor: pointer;
}

.create-message__groupnote {
  font-size: 10px;
  line-height: 1.1;
  color: #cccccc;
}

span.create-message__groupcheck {
  padding: 5px;
  left: -7px;
  top: -2px;
  position: absolute;
}

/* ------------------ ------------------ */
.create-message__top {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
}

.create-message__title {
  flex: 1
}

.create-message__fq {
  width: 75px;
}

.create-message__datetime {
  width: 200px;
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
  justify-content: space-between;
  margin-left: 10px;
  /*color: #555555*/
}

.create-message__datetime-time .MuiInputAdornment-root {
  display: none;
}

.create-message__datetime-time .MuiFormLabel-root,
.create-message__datetime-time .MuiInputBase-input {
  text-indent: 10px;
}
/*.create-message__datetime-field .MuiInputAdornment-root .MuiButtonBase-root{*/
/*  user-focus: none;*/
/*}*/
.create-message__preview {
  background-color: #2969ed;
  /*border: 1px solid gray;*/
  margin: 0 0 10px;
  padding: 10px;
  border-radius: 20px;
  display: inline-block;
  text-align: left;
}

.create-message__preview div {
  font-family: sans-serif;
  line-height: 1.3;
  font-size: 14px;
  margin-bottom: 0;
  color: white;
  /*text-shadow: 0 1px #2050b4;*/
}

/*.create-message__preview:hover {*/
/*  background-color: #1d54c5;*/
/*  cursor: pointer;*/
/*}*/
/* ------------------ ------------------ */
/*    min-height: 200px;*/
/*    height: 100%;*/
/* ------------------ ------------------ */
.fqnodata {

}

.fqnodata .btn-warning {
  color: white;
}

.fq-table__searches th, .fq-table__searches td {
  padding: 0;
}

.fq-table__searches .MuiFormControl-root {
  margin: 0;
}

.fq-add-name {
  background: #19201f;
  padding: 13px 14px;
  border-radius: 10px;
}
.fq-add-name__row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}
.fq-add-name__autocomplete {
  flex: 1;
  margin-right: 10px;
}
/* ------------------ ------------------ */
.auth__email {
  font-size: 11px;
}

.auth__logout {
  border-bottom: 1px dotted white;
}

/* ------------------ ------------------ */
.doc {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 25;
}

/* ------------------ ------------------- */
.updater {
  position: fixed;
  z-index: 200;
  /*pointer-events: none;*/
  user-select: none;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .80);
}

.updater a {
  text-decoration: underline;
}

/* ------------------ ------------------- */
.settings__group {

}

.settings__group .MuiButtonBase-root {
  padding: 0;
}

.settings__group label {
  margin: 0;
  margin-left: 6px;
  position: relative;
  top: 1px;
}

/* ------------------ ------------------- */

.copy-feedback {
  width: max-content;
  position: absolute;
  white-space: nowrap;
  top: -28px;
  left: 0;
  padding: 2px 4px;
  color: white;
  background-color: #183433;
  border-radius: 4px;
  font-size: 12px;
  z-index: 999999999999;
  pointer-events: none;
  user-select: none;
}

/* ------------------ ------------------ */
.messages__anotherfq {
  margin: 2px 0 10px;
  padding: 5px;
}

/* ------------------ ------------------ */
.messages-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
/* ------------------ ------------------ */
.message {
  background-color: #252c3b;
  margin: 5px 0 10px;
  /*border-left: 8px solid #2969ee;*/
  padding: 5px 5px 5px 40px;
  position: relative;
}
.message__copy {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 25px;
  background-color: var(--chattitle);
  cursor: pointer;
  opacity: .4;
}
.message__copy:hover {
  opacity: .8;
}
.message__copy:active {
  opacity: .2;
}

.message__text {}
.message__text-marked {
  /*background-color: #323e59;*/
  background-color: inherit;
  color: #efff00;
  /*font-weight: bold;*/
  /*padding: 0 0.2em;*/
  padding: 0;
}
.message__comment {
  font-style: italic
}
.message__callsigns {
  text-transform: uppercase;
  margin: 5px 0 8px;
}
.message__signal {
  font-size: 50%;
}
.message__datetime {
  color: #6a94b9;
  user-select: none;
}

/* ------------------ ------------------ */
.fq__loc {}
.fq__loc-item {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2px 0 7px;
}
.fq__loc-list {
  margin: 4px 0 20px;
}
.fq__loc {
  /*margin: 4px 0 5px;*/
}

.fq__loc-row {
  display: flex;
  flex-direction: row;
}
.fq__loc-fields {
  width: 280px;
  padding-right: 10px;
}
.fq__loc-map {
  flex: 1;
}
.fq__loc-mapzone {
  flex: 1;
  min-height: 100px;
  background-color: rgba(255, 255, 255, .1);
  text-align: center;
  padding-top: 35px;
}

/* ------------------ ------------------ */
.fq__factions {
  margin-top: 25px;
  background-color: #182610;
  border-radius: 6px;
  margin-bottom: 20px;
}
.fq__factions-row {
  padding: 8px;

}
/*.fq__faction:hover {}*/
.fq__factions-fields {
  flex: 1
}
.fq__faction {
  margin-left: 14px;
}
.fq__faction-option {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.fq__faction-option_secondrow {
  color: #cccccc;
}
.fq__faction-name {
  line-height: 1.2;
}
.fq__faction-parents {
  font-size: 80%;
  color: #cccccc;
  line-height: 1.2;
}
.fq__factions-list {
  margin-bottom: 25px;
}
